/*======================== 
    Common Style Css
==========================*/

/* Heading Font  */
.h1 {
  font-size: var(--h1) !important;
}

.h2 {
  font-size: var(--h2) !important;
}

.h3 {
  font-size: var(--h3) !important;
}

.h4 {
  font-size: var(--h4) !important;
}

.h5 {
  font-size: var(--h5) !important;
}

.h6 {
  font-size: var(--h6) !important;
}

/* Text Color  */

.color-primary {
  @extend %color-primary;
}
.color-secondary {
  @extend %color-secondary;
}

.color-tertiary {
  @extend %color-tertiary;
}
.color-heading {
  @extend %color-heading;
}
.color-body {
  @extend %color-body;
}
.color-dark {
  @extend %color-dark;
}
//===== BG Ncc Colors =====//

.bg-primary-color {
  background: var(--color-primary) !important;
}
.bg-secondary-color {
  background: var(--color-secondary) !important;
}
.bg-color-tertiary {
  background-color: var(--color-tertiary) !important;
}
.bg-color-white {
  background-color: var(--color-white) !important;
}

//===== Colors =====//
.color-white {
  color: var(--color-white);
}

/* Radius */
.radius {
  @extend %radius;
}

.radius-big {
  @extend %radius-big;
}

/* Font Weight */

.w-300 {
  @extend %w-300;
}

.w-400 {
  @extend %w-400;
}

.w-500 {
  @extend %w-500;
}

.w-600 {
  @extend %w-600;
}

.w-700 {
  @extend %w-700;
}

.w-800 {
  @extend %w-800;
}

.w-900 {
  @extend %w-900;
}

/* Shadows */

.shadow-primary {
  @extend %shadow-primary;
}

.shadow-light {
  @extend %shadow-light;
}

.shadow-lighter {
  @extend %shadow-lighter;
}

/* Others  */
.liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

.radius {
  @extend %radius;
}

%transition-transform {
  transition: var(--transition-transform);
}

.transition-transform {
  @extend %transition-transform;
}

.list-icon {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    margin: 15px 0;

    @media #{$sm-layout} {
      font-size: 16px;
    }

    .icon {
      width: 30px;
      background-color: var(--color-blackest);
      height: 30px;
      border-radius: 100%;
      display: inline-block;
      position: relative;
      margin-right: 9px;
      min-width: 30px;
      min-height: 30px;

      &.background-transparent {
        background-color: transparent;
      }

      i {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.rn-sub-badge {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 7px 14px;
  background-color: var(--color-blackest);
  box-shadow: var(--shadow-lighter);
}

.radius-small {
  border-radius: var(--radius-small);
}

.line-before {
  position: relative;
  padding-left: 18px;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 2px;
    height: 81%;
    background: var(--color-primary);
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-image--7 {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top 15px left 10%;
}

.bg-image--12 {
  background-size: auto;
}

.border-bottom-1 {
  border-bottom: 1px solid #eeeeee;
}

/* Breadcrumb  */
.edu-breadcrumb-area {
  background-image: url(../../images/bg/breadcrumb-bg.jpg);
  padding-top: 150px !important;
  .shape-image {
    position: absolute;

    z-index: -1;
    &.shape-image-1 {
      top: -30px;
      left: -180px;
    }
    &.shape-image-2 {
      top: -10px;
      left: 60%;
    }
    &.shape-image-3 {
      top: -24px;
      right: -90px;
    }
    &.shape-image-4 {
      bottom: -39px;
      right: -220px;
    }
    &.shape-image-5 {
      bottom: -28px;
      left: 35%;
    }
    &.shape-image-6 {
      bottom: -30px;
      left: -108px;
    }
  }
}

/* Animation  */

.mediashark-animated-shape {
  position: relative;
  z-index: 9;
}

@-webkit-keyframes mediashark-vsm-y-move {
  0% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
  }

  100% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
  }
}

@keyframes mediashark-vsm-y-move {
  0% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
  }

  100% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
  }
}

@-webkit-keyframes mediashark-vsm-y-reverse-move {
  0% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
  }

  100% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
  }
}

@keyframes mediashark-vsm-y-reverse-move {
  0% {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
  }

  100% {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
  }
}

@-webkit-keyframes mediashark-sm-x-move {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  100% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
  }
}

@keyframes mediashark-sm-x-move {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  100% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
  }
}

@-webkit-keyframes mediashark-sm-x-reverse-move {
  0% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
  }

  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@keyframes mediashark-sm-x-reverse-move {
  0% {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
  }

  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@-webkit-keyframes mediashark-rotate-y {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes mediashark-rotate-x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mediashark-rotate-x {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mediashark-rotate-y {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes mediashark-zoom1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes mediashark-zoom2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes mediashark-zoom1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}

@keyframes mediashark-zoom1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}

.eduloans-container {
  margin: 140px auto 20px auto;
  // border: 1px solid rgb(196, 194, 194);
  display: flex;
  gap: 20px;
  max-width: 1440px;
  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin: auto;
    padding: 10px 0;
  }

  .title {
    border-radius: 10px;
  }

  .left-sidebar {
    width: 250px;
    position: fixed;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    // border: 1px solid #ddd;
    border-radius: 10px;
    // add shadow
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .menu {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      .menu-title {
        width: 100%;
        text-align: left;
        background-color: #ddd;
        color: white;
        padding: 0 10px;
        font-size: 14px;
      }
      .submenu {
        margin-left: 20px;
        li {
          font-size: 14px;
        }
      }
    }
  }

  .content {
    width: calc(100% - 250px);
    margin-left: 270px;
    border-radius: 5px;
  }

  .features {
    // border: 1px solid red;
    position: relative;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    // border: 1px solid #ddd;
    // add shadow
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: black;
      margin: 10px 0;
      border-bottom: 5px solid var(--color-primary);

      // min-width: max-content;
      // max-width: 90%;
      width: max-content;
      margin: auto;
    }
    .plus-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
    p {
      font-size: 16px;
      color: #000;
      margin-top: 10px;
      // margin-left: 20px;
    }

    .tile {
      li {
        font-size: 18px;
        color: var(--color-primary);

        font-weight: bold;
        // change bullet point
      }

      .expense-list {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        gap: 0;

        li {
          font-size: 16px;
          color: #000;
          font-weight: normal;
          // border: 1px solid red;
          margin: 4px 0px;
          list-style: disc;
          // change bullet to counter
        }
      }

      .document-list {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        gap: 0;

        li {
          font-size: 16px;
          color: #000;
          font-weight: normal;
          // border: 1px solid red;
          margin: 4px 0px;
          // list-style: disc;
          // change bullet to counter
        }
      }

      p {
        font-size: 16px;
        color: #000;
        margin-top: 10px;
        // margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .eduloans-container {
    margin: 140px 10px 20px 10px;

    .left-sidebar {
      display: none;
    }

    .content {
      width: 100%;
      margin-left: 0;
    }

    .features {
      padding: 10px 20px;

      width: 100%;

      // border: 1px solid red;
      h3 {
        font-size: 18px;
        // min-width: max-content;
        // border: 1px solid red;
        // white-space: normal;
        // word-wrap: break-word;
        // min-width: max-content;
        width: 90%;

        // the text is too long for mobile view so we need to break it into 2 lines , give me css '
      }

      .tile {
        li {
          font-size: 16px;
          color: var(--color-primary);
          font-weight: bold;
          // change bullet point
        }

        .expense-list {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          li {
            font-size: 12px;
          }
          table {
            font-size: 12px;
          }
          p {
            margin-left: 0px;
            font-size: 12px;
          }
        }

        .document-list {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          li {
            font-size: 12px;
          }
          table {
            font-size: 12px;
          }
          p {
            margin-left: 0px;
            font-size: 12px;
          }
        }

        p {
          margin-left: 0px;
          font-size: 12px;
        }
      }
    }

    p {
      margin-left: 0px;
      font-size: 12px;
    }

    h2 {
      font-size: 20px;
    }
  }
}

.gd-infoheader {
  background-color: var(--color-primary);
  height: 30px;
  z-index: 10;
  position: fixed;
  width: 100%;
  left: 0px;
}
.gd-infoheader-shift {
  margin-top: 2px;
}
.gd-infoheader p {
  color: #fff;
}

.gd-infoheader p a {
  color: #fff;
}

.logo a {
  display: block;
}

.shape-image {
  opacity: 0;
}

.gd-icon-bg {
  background: rgba(225, 31, 34, 0.2078431373) !important;
}
