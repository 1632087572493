/* Forex.css */

/* Main container */
.forex {
  width: 100%;
  height: 100%;
  /* Add any other styles you want for the main container */
}

/* First section */
.first {
  /* border: 1px solid red; */
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* Add any other styles you want for the first section */

  /* Mobile-responsive styles */
  @media (max-width: 768px) {
    height: 80px;
    justify-content: flex-end;
    padding: 10px;
  }
}

/* Logo container */
.logo {
  display: flex;
  /* border: 1px solid blue; */
  /* Add any other styles you want for the logo container */

  /* Mobile-responsive styles */
  @media (max-width: 768px) {
  }
}

/* Logo images */
.logo img {
  margin-right: 15px;
  height: 80px;
}

/* Styling for the left border of the second logo */
.logo img:last-child {
  /* border-left: 2px solid gray; */
}

.forex .second {
  /* border: 1px solid blue; */
  background-color: #13589d;
  height: 530px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.forex .second .advertise {
  width: 50%;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forex .second .advertise h3 {
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}

.forex .second .advertise .desc {
  background-color: #df1e23;
  width: 80%;
  margin: 0 auto;
}

.forex .second .form {
  width: 50%;
  /* border: 1px solid violet; */
}

.forex .second .form form {
  width: 80%;
  margin: 10px auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.forex .second .form form .col-lg-12 {
  /* ... Form column styles ... */
}

.forex .second .form form .form-group {
  /* ... Form group styles ... */
}

.forex .second .form form .form-control {
  /* ... Form control styles ... */
}

.forex .second .form form .rn-btn {
  /* ... Submit button styles ... */
}

.forex .second .form form .form-group .success-message {
  /* ... Success message styles ... */
}

.forex .second .form form .form-group .error-message {
  /* ... Error message styles ... */
}

/* THIRD SECTION */

.third {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.third h3 {
  font-size: 3.5rem;
  background-color: #df1e23;
  border-radius: 20px;
  width: 70%;
  color: white;
  margin: 10px auto;
}

.third .img {
  /* border: 1px solid blue; */
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  margin-top: 10px;
}

.third .img .card {
  /* border: 1px solid red; */
  border: none;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.third .img .card img {
  width: 150px;
}

.third .img .card p {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #252525;
}

.fourth .features h3 {
  font-size: 3.2rem;
  background-color: #1779db;
  border-radius: 20px;
  width: 50%;
  color: white;
  margin: 0px auto;
  position: relative;
  top: 40px;
}

/* style.css */

.fourth {
  background-color: #13589d;
  height: 400px;
}

.features h3 {
  text-align: center;
  color: white;
  font-size: 20px;
  /* font-size: 1.2rem; */
}

.contact {
  position: relative;
  top: 90px;
  /* border: 1px solid red; */
  font-size: 20px;
  height: 60px;
  width: 60%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.contact1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  width: 30%;
}

.contact1 img {
  width: 60px;
  background-color: white;
}

.contact1 p {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin: auto 10px;
  /* border: 1px solid red; */
  width: 100%;
}

.address {
  /* border: 1px solid #b6a9a9; */
  position: relative;
  width: 60%;
  top: 120px;
  display: flex;
  align-items: center;
  margin: auto;
}

.address img {
  width: 80px;
}

.address span {
  height: 80px;
  border: 2px solid #ffffff;
}

.address p {
  /* border: 1px solid #b6a9a9; */
  font-size: 20px;
  color: white;
  margin: auto;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .logo img {
    margin-right: 5px;
    /* max-width: 90px; */
    max-height: 50px;
  }
  .forex .second {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .forex .second .advertise {
    margin: 40px 0px;
  }

  .forex .second .advertise,
  .forex .second .form {
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }

  .forex .second .advertise h3,
  .forex .second .form form {
    width: 90%;
  }

  .forex .second .form form {
    padding: 15px;
  }

  .third {
    height: auto; /* Allow height to adjust based on content */
  }

  .third h3 {
    font-size: 1.7rem;
    width: 90%;
  }

  .fourth .features h3 {
    font-size: 1.7rem;
    width: 60%;
  }

  .fourth {
    height: 450px;
  }

  .third .img {
    width: 90%;
    flex-wrap: wrap;
  }

  .third .img .card {
    width: 150px;
    height: 150px;
  }

  .third .img .card img {
    width: 120px;
  }

  .third .img .card p {
    font-size: 1.2rem;
    text-align: center;
  }

  .third .img #card1 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .contact {
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    width: 80%;
  }

  .contact1 {
    width: 100%;
    margin-bottom: 10px;
  }

  .contact1 img {
    width: 40px;
  }

  .contact1 p {
    font-size: 15px;
  }

  .address {
    /* flex-direction: column; */
    width: 90%;
    align-items: center;
  }

  .address img {
    margin-bottom: 10px;
  }

  .address p {
    font-size: 14px;
    text-align: center;
  }
}
