/*------------------------
    About Styles  
-------------------------*/

.about-feature-list {
  .our-feature {
    display: flex;
    .icon {
      width: 50px;
      min-width: 50px;
      height: 50px;
      background: var(--color-secondary);
      display: flex;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      i {
        color: var(--color-white);
        font-size: 24px;
      }
    }
    .feature-content {
      .feature-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 5px;
      }
      .feature-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
      }
    }

    & + .our-feature {
      margin-top: 30px;
    }
  }
}

/*----------------------
    About Style One  
------------------------*/

.about-style-1 {
  position: relative;
  z-index: 1;
  .about-image-gallery {
    margin-right: 100px;
    position: relative;
    @media #{$lg-layout} {
      margin-right: 60px;
    }
    @media #{$md-layout} {
      margin-right: 60px;
    }
    @media #{$sm-layout} {
      margin-right: 0;
    }
    .image-1 {
      border-radius: 5px;
      width: 100%;
    }
    .image-2 {
      position: absolute;
      bottom: -61px;
      right: -50px;
      border-radius: 5.2px;
      @media #{$sm-layout} {
        position: absolute;
        right: 0;
        border-radius: 5px;
        margin-top: 30px;
        transform: none !important;
        object-fit: cover;
      }
      @media #{$large-mobile} {
        display: none;
      }
      img {
        border-radius: 5px;
      }
    }
    .badge-inner {
      position: absolute;
      top: 42px;
      left: -75px;
      width: 150px;
      height: 150px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(8px);
      border-radius: 100%;
      @media #{$large-mobile} {
        display: none;
      }
      .image-3 {
        animation: rotateIt 7s linear infinite;
        border-radius: 100%;
      }
    }
    .shape-image-1 {
      position: absolute;
      bottom: -35px;
      left: -35px;
      z-index: -1;
    }
  }
  .inner {
    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      padding-left: 17px;
      position: relative;
      margin-top: 40px;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 2px;
        height: 46px;
        background: var(--color-primary);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .read-more-btn {
      margin-top: 40px;
    }
    .about-parallax-2 {
      position: absolute;
      right: 88px;
      top: 379px;
      animation: mediashark-vsm-y-move 3.5s alternate infinite linear;
      @media #{$laptop-device} {
        right: 8px;
      }
    }
  }
}

/*----------------------
    About Style Two  
------------------------*/
.about-style-2 {
  position: relative;
  .about-image-gallery {
    position: relative;
    .mediashark-about-1-img-wrapper {
      position: relative;
      .image-1 {
        position: relative;
        margin-left: -40px;
        width: 460px;
        min-width: 460px;
        height: 460px;
        min-height: 460px;
        border-radius: 100%;
        z-index: 3;
        @media #{$lg-layout} {
          margin-left: 0;
        }

        @media #{$md-layout} {
          margin-left: 0;
        }

        @media #{$sm-layout} {
          margin-left: 0;
          width: 100%;
          min-width: auto;
          min-height: auto;
          height: auto;
        }
      }
      .mediashark-about-blur img {
        position: absolute;
        left: -50px;
        width: 739px;
        height: 739px;
        z-index: 0;
        top: -140px;
        @media #{$smlg-device} {
          display: none;
        }
      }
    }
    .image-2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 100%;
      @media #{$md-layout} {
        right: 101px;
      }
    }
    .finished-session {
      background: var(--color-white);
      box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.05);
      width: 170px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      position: absolute;
      left: -120px;
      top: 8%;
      z-index: 3;
      @media #{$lg-layout} {
        left: 0;
        top: -9%;
      }

      @media #{$md-layout} {
        left: 0;
        top: -9%;
      }

      @media #{$sm-layout} {
        left: 0;
        top: 0;
        width: 120px;
        height: 120px;
      }

      .inner {
        .text {
          font-weight: 700;
          font-size: 32px;
          color: var(--color-primary);
          line-height: 44px;
          display: block;
          @media #{$sm-layout} {
            font-size: 22px;
            line-height: 29px;
          }
        }
        .finished-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          display: inline-block;
          color: var(--color-dark);
        }
      }
    }
  }

  .circle-image-wrapper {
    position: absolute;
    width: 272px;
    height: 272px;
    right: 52px;
    bottom: -57px;
    z-index: 3;
    .circle-image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 272px;
      height: 272px;
      z-index: -1;
      span {
        border-color: #ffa41b;
        left: 26px;
        @media #{$small-tablet} {
          left: -51px;
        }
      }
    }
    @media #{$sm-layout} {
      display: none;
    }
    @media #{$laptop-device} {
      right: 30px;
    }
  }
}

.circle-image-wrapper {
  position: relative;
  display: inline-block;
  z-index: 1;
  .circle-image {
    z-index: -1;
  }
}

/*----------------------
    About Style 3  
------------------------*/
.about-style-3 {
  position: relative;
  .gappery-wrapper {
    .gallery-image {
      position: relative;
      img {
        border-radius: 5.2px;
        @media #{$md-layout} {
          width: 100%;
        }
        @media #{$sm-layout} {
          width: 100%;
        }
      }
      .icon-badge {
        width: 60px;
        height: 60px;
        background: var(--color-primary);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);

        i {
          color: var(--color-white);
          font-size: 20px;
        }
      }

      &.gallery-image-3 {
        .student-like-status {
          background: #ffffff;
          box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          display: inline-block;
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translateY(-50%);

          @media #{$md-layout} {
            left: 10px;
            top: auto;
            transform: none;
            bottom: 10px;
          }
          @media #{$sm-layout} {
            left: 10px;
            top: auto;
            transform: none;
            bottom: 10px;
          }
          .inner {
            display: flex;
            padding: 16px 15px;
            align-items: center;
            .icon {
              width: 40px;
              height: 40px;
              background: rgba(251, 124, 86, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              margin-right: 10px;
              i {
                color: var(--color-white);
              }
            }
            .content {
              text-align: left;
              .title {
                color: var(--color-secondary);
                // font-weight: 700;
                font-size: 20px;
                line-height: 16px;
                margin-bottom: 0;
              }
              .subtitle {
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}

/*----------------------
    About Style 4  
------------------------*/
.about-style-4 {
  position: relative;
  .gallery-wrapper {
    position: relative;
    text-align: center;
    margin-right: 120px;
    margin-left: -50px;
    z-index: 2;
    @media #{$lg-layout} {
      text-align: left;
      margin-left: 130px;
      margin-right: 0;
    }
    @media #{$md-layout} {
      margin-right: 50px;
    }
    @media #{$sm-layout} {
      margin-right: 0;
      margin-left: 0;
      text-align: left;
    }
    img {
      border-radius: 5px;
    }
    .image-1 {
      @media #{$sm-layout} {
        width: 100%;
      }
    }
    .image-2 {
      position: absolute;
      left: -95px;
      z-index: -1;
      top: 88px;

      @media #{$lg-layout} {
        left: -120px;
      }

      @media #{$md-layout} {
        left: 3%;
      }

      @media #{$sm-layout} {
        display: none;
      }
    }
    .image-3 {
      position: absolute;
      right: -73px;
      bottom: 100px;

      @media #{$lg-layout} {
        left: 370px;
        right: inherit;
      }

      @media #{$md-layout} {
        right: 0;
      }

      @media #{$sm-layout} {
        right: 0;
      }
      @media #{$large-mobile} {
        display: none;
      }
      img {
        border: 3px solid #ffffff;
      }
    }
  }
}

/*----------------------
    About Style 6  
------------------------*/

.about-style-6 {
  .about-image-gallery {
    position: relative;
    z-index: 1;
    img {
      border-radius: 265px 265px 5px 5px;

      @media #{$md-layout} {
        border-radius: 500px 500px 0 0;
      }
      @media #{$sm-layout} {
        border-radius: 500px 500px 0 0;
      }
    }
    .badge-icon {
      position: absolute;
      bottom: 173px;
      left: -75px;
      width: 130px;
      height: 130px;
      background: #ffffff;
      border-radius: 100%;
      padding: 5px;

      @media #{$lg-layout} {
        bottom: auto;
        left: 0;
        top: 0;
      }

      @media #{$md-layout} {
        bottom: auto;
        left: 0;
        top: 0;
      }

      @media #{$sm-layout} {
        bottom: auto;
        left: 0;
        top: 0;
      }

      .badge-inner {
        padding: 5px;
        background: #e11f23;
        border-radius: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;

        &::after {
          width: 20px;
          height: 20px;
          background: var(--color-white);
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
        }
        img {
          animation: rotateIt 7s linear infinite;
        }
      }
    }
    &::after {
      position: absolute;
      content: "";
      border: 5px solid var(--color-secondary);
      width: 97%;
      height: 95%;
      left: 40px;
      bottom: -20px;
      border-radius: 265px 265px 5px 5px;
      z-index: -1;
      @media #{$md-layout} {
        border-radius: 500px 500px 0 0;
      }
      @media #{$sm-layout} {
        display: none;
      }
    }
  }
  .inner {
    @media #{$lg-layout} {
      padding-left: 40px;
    }
    .feature-list-wrapper {
      display: flex;
      margin: 0;
      margin-top: -30px;
      .feature-list {
        flex-basis: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: var(--color-heading);
        margin-top: 30px;
        @media #{$lg-layout} {
          flex-basis: 100%;
          margin-top: 15px;
        }

        @media #{$md-layout} {
          margin-top: 15px;
        }

        @media #{$sm-layout} {
          margin-top: 15px;
        }

        @media #{$large-mobile} {
          flex-basis: 100%;
        }

        i {
          margin-right: 14px;
          color: var(--color-secondary);
          font-size: 24px;
        }
      }
    }
    .info-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-heading);
      margin-left: 30px;
      transition: 0.4s;

      @media #{$lg-layout} {
        margin-left: 10px;
      }

      @media #{$large-mobile} {
        margin-left: 0;
        margin-top: 20px;
        display: block;
      }

      &:hover {
        color: var(--color-secondary);
      }
      i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 100%;
        text-align: center;
        margin-right: 15px;
        background: rgba(251, 124, 86, 0.15);
        color: var(--color-secondary);
        display: inline-block;
      }
    }

    .info-btn-og {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-heading);
      margin-left: 30px;
      transition: 0.4s;

      @media #{$lg-layout} {
        margin-left: 10px;
      }

      @media #{$large-mobile} {
        margin-left: 0;
        margin-top: 20px;
        display: block;
      }

      &:hover {
        color: var(--color-secondary);
      }
      i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 100%;
        text-align: center;
        margin-right: 5px;
        background: var(--color-secondary);
        color: var(--color-white);
        display: inline-block;
      }
    }

    .info-btn-og-2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-heading);
      margin-left: 5px;
      transition: 0.4s;

      @media #{$lg-layout} {
        margin-left: 10px;
      }

      @media #{$large-mobile} {
        margin-left: 0;
        margin-top: 20px;
        display: block;
      }

      &:hover {
        color: var(--color-secondary);
      }
      div {
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 100%;
        border: 1px solid white;
        text-align: center;
        margin-right: 15px;
        background: #46c254;
        color: var(--color-white);
        display: inline-block;
        .gd-icon-whatsapp {
          width: 25px;
          height: 25px;
          margin-top: -5px;
        }
      }
    }
  }
}

.edu-mission-vision-area {
  .thumbnail {
    margin: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    @media #{$md-layout} {
      flex-wrap: wrap;
    }
    @media #{$sm-layout} {
      flex-wrap: wrap;
    }
    img {
      border-radius: 5px;
    }
    .shape-image {
      padding: 15px;
      @media #{$md-layout} {
        flex-basis: 50%;
      }
      @media #{$sm-layout} {
        flex-basis: 50%;
      }
      @media #{$large-mobile} {
        flex-basis: 100%;
        text-align: center;
      }
    }
  }
}

.mediashark-about-three-mission {
  .thumbnail img {
    @media #{$small-tablet} {
      transform: none !important;
    }
  }
}

/*----------------------
    Why Choose Us 1
------------------------*/
.edu-choose-us-area-one {
  .thumbnail {
    margin-left: -151px;
    margin-right: -76px;
    margin-top: -50px;
    @media #{$custom-laptop-device} {
      margin-left: -60px;
    }
    @media #{$smlg-device} {
      margin-left: -120px;
      margin-right: 0;
    }
    @media #{$small-tablet} {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }
  &.edu-section-gap {
    padding: 180px 0 130px !important;
    @media #{$md-layout} {
      padding: 80px 0 !important;
    }
    @media #{$sm-layout} {
      padding: 80px 0 !important;
    }
  }
}
