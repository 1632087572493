.unique-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px) {
  .unique-container {
    grid-template-columns: 1fr;
  }
}
