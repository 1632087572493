.edu-form-container {
  width: 100%;
  margin-top: 110px;
  /* border: 1px solid #e5e5e5; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-side {
  width: 50%;
  background-color: #fff;
  height: 100vh;
  padding: 60px;
}

.right-side {
  width: 50%;
  background-color: #13589d;
  height: 100vh;
  padding: 60px;

  .title {
    color: #fff;
    font-size: 1.8em;
    text-align: center;
  }

  .terms-conditions {
    height: 50px;
    color: #fff;
    font-size: 0.8em;
    text-align: center;
  }
}

.title {
  color: #fff;
  font-size: 36px;
  text-align: center;
  padding: 40px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.input-style {
  width: 80%;
  height: 50px;

  border: 1px solid #fff;
  padding: 10px;
  margin-bottom: 10px;
}

/* add media query for mobile */
@media (max-width: 768px) {
  .edu-form-container {
    flex-direction: column;
  }

  .left-side {
    display: none;
  }

  .right-side {
    width: 100%;
    background-color: #13589d;
    height: auto;
    padding: 0px;

    .title {
      color: #fff;
      font-size: 1.1em;
      text-align: center;
    }

    .terms-conditions {
      /* height: 50px; */
      color: #fff;
      font-size: 0.7em;
      text-align: center;
    }
  }
}
