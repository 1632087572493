.allied-services {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  gap: 30px;
  padding: 10px;
  margin: 120px 0px 0px 0px;
}

.title {
  color: red;
  font-size: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .allied-services {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 150px 0px 150px;
    margin: 150px 0px 0px 0px;
  }

  .title {
    color: red;
    font-size: 30px;
    text-align: center;
  }
}

.card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}
